import { getDefaultNormalizer } from "@testing-library/react";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbarm from "../Navbar/navbar";
import Footer from "../Navbar/Footer";


const Impressum =()=>{

    return(
        <div className="header-footer1"  > 
        <Navbarm/>
        <div fluid className="header-footer1 text-center" style={{width:"100%", height:"100%"}}>
        <h2 style={{color:"white"}}>Impressum</h2>
        </div>
        
        <Container fluid className="custom-section">

      
<Row className="justify-content-center">
    <Col md={8} className="text-center">
      <div style={{color:"white"}} className='title3 text-start'>

      
      <h1  style={{color:"gold"}}>Angaben gemäß § 5 TMG</h1>
      
      <p>
      [Unternehmen, Rechtsform]
Lindenstraße 19A
31535 Neustadt am Rübenberge
Handelsregister: 220506
Registergericht: Hannover
Vertreten durch:
Imededin Mohamed Hasan Mohamed
      </p>
      <h6  style={{color:"gold"}}>Kontakt</h6>
      <p>
      Telefon: 01794484651
E-Mail: info@highlevel-media.de
      </p>
      <h6  style={{color:"gold"}}>Umsatzsteuer-ID</h6>
      <p>
      Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
DE337447356
      </p>
      <h6 style={{color:"gold"}}>Redaktionell verantwortlich</h6>
      <p>
      Imededin Mohamed Hasan Mohamed


      </p>
      <h6  style={{color:"gold"}}>EU-Streitschlichtung</h6>
      <p>
      Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
https://ec.europa.eu/consumers/odr/.
Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>
      <h6  style={{color:"gold"}}>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h6>
      <p>
      Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
Verbraucherschlichtungsstelle teilzunehmen
      </p>
      </div>
      
    </Col>
  </Row>
  </Container>
  <Footer/>
  </div>
    )
}
export default Impressum