import React from "react";
import {Navbar, Container, Nav, ProgressBar, Row,Col, Button, Image, Card, Carousel } from "react-bootstrap"
import Homepage from "./compoment/Home/hompage";
import LeistungenSection from "./compoment/page/Leistung";
import UberUns from "./compoment/Navbar/uberUns";
import KontaktFormular from "./compoment/Navbar/Kontakt";
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Impressum from "./compoment/ulitiy/impressum";
import Dataschutz from "./compoment/ulitiy/dataschutz";
import Cookis from "./compoment/ulitiy/cookis";

function App() {

  return (
    <div>
     


<BrowserRouter>
<Routes>
<Route index element={<Homepage />} />


          <Route path="/leistung" element={<LeistungenSection />} />
          <Route path="/uberunns" element={<UberUns />} />
          <Route path="/kontakt" element={<KontaktFormular />} />
          <Route path="/impressum" element={<Impressum/>} />
          <Route path="/datenschutz" element={<Dataschutz/>} />
          <Route path="/cookie" element={<Cookis/>} />
          
</Routes>
</BrowserRouter>

          
         




      
    </div>
  
  );
}

export default App;