import React from 'react'
import { Container, Col ,Row} from "react-bootstrap";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='justify-content-center text-center' style={{height:"66px", backgroundColor:"blac"}} >
            
             
        <div className='header-footer'>
        <Link to="/impressum">
          <div className="footer-shroot">Impressum</div>
        </Link>
        <Link to="/datenschutz">
          <div className="footer-shroot">Datenschutzerklärung</div>
        </Link>
        <Link to="/cookie">
          <div className="footer-shroot">Cookie-Richtlinie (EU)</div>
        </Link>
      </div>
      <Link to="/impressum">
          
        </Link>
                  
          
           
        </div>
    )
}

export default Footer
