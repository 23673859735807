import React from "react";
import {Nav, Container, Navbar} from 'react-bootstrap';
import { Link } from "react-router-dom";
import logoImage from "../../images/logo - Kopie.png";




const Navbarm =()=>{

    return(
        <div>
          <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/">
          <img
            src={logoImage}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="High Level Media Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" style={{ color: "gold" }}>
            <Nav.Link> <Link to="/"><h6 style={{ color: "gold" }}>Startseite</h6></Link></Nav.Link>
            <Nav.Link> <Link to="/leistung"><h6 style={{ color: "gold" }}>Leistungen</h6></Link></Nav.Link>
            <Nav.Link> <Link to="/uberunns"><h6 style={{ color: "gold" }}>Über Uns</h6></Link></Nav.Link>
            <Nav.Link> <Link to="/kontakt"><h6 style={{ color: "gold" }}>Kontakt</h6></Link></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
      </div>


    )

}

export default Navbarm