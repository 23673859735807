import React from 'react';
import { Container, Row, Col, Card,Button } from 'react-bootstrap';
import Navbarm from '../Navbar/navbar';
import Footer from '../Navbar/Footer';
import Leistung from "../../images/Leistung1.png"
import { Link } from 'react-router-dom';

const LeistungenSection = () => {
  // Die Inhalte könnten auch aus einem State oder Props geladen werden
  const leistungen = [
    {
      kategorie: 'Social Media Branding',
      text:  " Ihre Marke, Ihr Charakter – überall.“ Die Konsistenz Ihrer Marke auf verschiedenen Plattformen ist entscheidend für den Wiedererkennungswert. Unser Team arbeitet eng mit Ihnen zusammen, um sicherzustellen, dass Ihr Branding überall einheitlich und ansprechend ist.",
    },
    {
      kategorie: 'Mitarbeitergewinnung',
      text:" „Finden Sie die Besten der Branche.“ In der heutigen digitalen Welt beginnt die Suche nach Talenten online. Wir nutzen zielgerichtete Social Media Strategien, um die richtigen Kandidaten für Ihr Unternehmen zu finden und anzusprechen."
    },
    {
        kategorie: 'Content Erstellung',
        text:"Inhalte, die begeistern.“Guter Content fesselt und informiert. Unsere Experten erstellen maßgeschneiderte Inhalte – von Blogbeiträgen bis hin zu Infografiken –, die Ihre Zielgruppe lieben wird."
      },
      {
        kategorie: 'Social Media Branding',
        text: "„Botschaften, die ankommen.“ Eine durchdachte Strategie ist der Grundstein für jeden Erfolg. Gemeinsam mit Ihnen entwickeln wir einen Plan, der Ihre Geschäftsziele in den Mittelpunkt stellt."
      },
      {
        kategorie: 'Strategieentwicklung',
        text: "Planung ist die halbe Miete. Erreichen Sie genau die Menschen, die Sie ansprechen möchten. Mit gezielten Werbekampagnen optimieren wir Ihre Anzeigen für maximale Effizienz."
      },
      {
        kategorie: 'Community Management',
        text: "Kommunikation, die verbindet. Eine aktive und engagierte Online-Community stärkt das Vertrauen in Ihre Marke. Wir interagieren in Ihrem Namen, beantworten Fragen und fördern positive Diskussionen."
      },
    
     
    // ...weitere Kategorien
  ];

  return (
    <div className="leistung-container1 m-0" style={{ backgroundColor:"black"}}>

    <Container style={{maxWidth:"100%"}}>
  

    <Navbarm/>
    <div className='leistung-container'>
    <Row className="m-0">
        <Col className="text-center">
          <h2 className="leistung-title text-section1 " >Leistungen</h2>
        </Col>
      </Row>
    </div>
    
  
   
    <Container fluid className='leistung-background '>
   
    <Row className="justify-content-md-center text-center m-0">
      <Col>
        
      </Col>
    </Row>
    <Row className="justify-content-md-center">
    
      {leistungen.map((leistung, index) => (
        <Col key={index} lg={5} className="mb-4"> {/* lg={6} ermöglicht zwei Karten nebeneinander in größeren Ansichten */}
          <Card className='card-background'>
            <Card.Body>
              <Card.Title  style={{ fontWeight:"bolder", textShadow:"black" }}>{leistung.kategorie}</Card.Title> {/* Gelbe Farbe für den Titel */}
              <Card.Text style={{ color: 'black' }}>
                {leistung.text}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
     
    </Row>
    
  </Container>
  <div >
  

  <Row  className='buttenLeistung m-0' style={{padding:"40px"}}>
  <Col className="text-center py-3">
  <Link to="/kontakt"> 
<Button
variant="warning"
size="lg"
style={{
  
borderRadius: '50px', // Correct style name and value
marginBottom: '20px', 
marginTop:"20px" // Adds space below the button
}}
>
Jetzt Kontakt aufnehmen

</Button>
</Link>
   
   
  </Col>
</Row>
</div>
  <Footer/>
  </Container>
  </div>
  );
};

export default LeistungenSection;
